import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { Section } from '@styles/globalComponents'
import FooterImage from '../../../static/images/subscribe_footer_1.png'

const SubscribeSection = styled(Section)`
  margin: 0;
  padding: 0;
  max-width: 100vw;
  display: grid;
  // background-color: #f6eceb;

  h2 {
    font-size: 1.5em;
    font-family: 'Kamelia';
    text-align: center;
  }
  @media (min-width: ${(props) => props.theme.screen.small}) {
    // grid-template-columns: 1fr 1fr;
  }
`

const ImageSubscription = styled.div`
  height: 250px;
  display: none;
  @media (min-width: ${(props) => props.theme.screen.small}) {
    display: block;
    height: 200px;
  }
`

const Email = styled.input`
  margin: 0 0 1em 0;
  width: 80%;
  @media (min-width: ${(props) => props.theme.screen.small}) {
    width: 70%;
  }
`

const Form = styled.form`
  max-width: ${(props) => props.theme.sizes.maxWidthCentered};
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  line-height: 2em;

  input,
  textarea {
    font-family: inherit;
    font-size: inherit;
    background: ${(props) => props.theme.colors.tertiary};
    color: ${(props) => props.theme.colors.text};
    border-radius: 2px;
    padding: 1em;
    &::-webkit-input-placeholder {
      color: gray;
    }
    &::-moz-placeholder {
      color: gray;
    }
    &:-ms-input-placeholder {
      color: gray;
    }
    &:-moz-placeholder {
      color: gray;
    }
    &:required {
      box-shadow: none;
    }
  }
  &::before {
    content: '';
    background: black;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    transition: 0.2s all;
    opacity: ${(props) => (props.overlay ? '.8' : '0')};
    visibility: ${(props) => (props.overlay ? 'visible' : 'hidden')};
  }
`

const Submit = styled.input`
  background: ${(props) => props.theme.colors.text} !important;
  color: white !important;
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    background: ${(props) => props.theme.colors.highlight} !important;
  }
`

const Subscribe = () => {
  return (
    <SubscribeSection>
      <h2>Subscribe</h2>
      {/* <ImageSubscription >
        <StaticImage src="../../../static/images/subscribe-banner-image-large.jpg" alt="Subscribe image" transformOptions={{rotate: 90}} height={350} width={900} />
      </ImageSubscription> */}
      <Form>
        <h1>Don't miss a recipe.</h1>
        <p>
          Sign up for the newsletter and get recipes delivered to your inbox
        </p>
        <Email name="email" type="email" placeholder="Email" required />
        <Submit name="submit" type="submit" value="Sign Up" />
      </Form>
    </SubscribeSection>
  )
}

export default Subscribe
