import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@components/Layout'
import LogoContainer from '@components/Logo'
import CardList from '@components/CardList'
import Card from '@components/Card'
import { Section, CategoryTitle } from '@styles/globalComponents'
import Pagination from '@components/Pagination'
import SEO from '@components/SEO'
import { startCase } from 'lodash'
import CategoryPosts from '@components/CategoryPosts'
import CategoryTabs from '@components/CategoryTabs'
import Subscribe from '../components/Subscribe'
import HomeAbout from '@components/HomeAbout'

const Posts = ({ data, pageContext }) => {
  const posts = data.allContentfulRecipePost.edges
  const pastriesPosts = data.pastries.edges
  const dessertPosts = data.dessert.edges
  const cookiesPosts = data.cookies.edges
  const { humanPageNumber, basePath } = pageContext

  // const isFirstPage = humanPageNumber === 1
  let ogImage

  try {
    ogImage = posts[0].node.heroImage.ogimg.src
  } catch (error) {
    ogImage = null
  }

  return (
    <Layout>
      <SEO title={startCase(basePath)} image={ogImage} />
      <LogoContainer />
      <Section>
        <CategoryTitle>Latest Recipes</CategoryTitle>
        {/* {isFirstPage ? (
          <CardList>
            <Card {...featuredPost} featured basePath={basePath} />
            {posts.slice(1).map(({ node: post }) => (
              <Card key={post.id} {...post} basePath={basePath} />
            ))}
          </CardList>
        ) : ( */}
        <CardList>
          {posts.map(({ node: post }) => (
            <Card key={post.id} {...post} withText={true} basePath={basePath} />
          ))}
        </CardList>
        {/* } */}
        <Pagination context={pageContext} />
      </Section>
      <CategoryPosts
        category="Pastries"
        posts={pastriesPosts.slice(0, 3)}
        nowrap={true}
        basePath={basePath}
      />
      <HomeAbout />
      <CategoryPosts
        category="Dessert"
        posts={dessertPosts.slice(0, 3)}
        nowrap={true}
        basePath={basePath}
      />
      <CategoryTabs
        dessert={dessertPosts}
        pastries={pastriesPosts}
        cookies={cookiesPosts}
        basePath={basePath}
      />
      <Subscribe />
    </Layout>
  )
}

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allContentfulRecipePost(
      sort: { fields: [publishDate], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title
          id
          slug
          publishDate(formatString: "MMMM DD, YYYY")
          heroImage {
            title
            gatsbyImageData(width: 1200, quality: 100, layout: CONSTRAINED)
            ogimg: resize(width: 1200) {
              src
            }
          }
          body {
            childMarkdownRemark {
              html
              excerpt(pruneLength: 160)
            }
          }
        }
      }
    }

    cookies: allContentfulRecipePost(
      filter: { tags: { elemMatch: { title: { eq: "Cookies" } } } }
      limit: 6
    ) {
      edges {
        node {
          ...Content
        }
      }
    }

    pastries: allContentfulRecipePost(
      filter: { tags: { elemMatch: { title: { eq: "Pastries" } } } }
      limit: 6
    ) {
      edges {
        node {
          ...Content
        }
      }
    }

    dessert: allContentfulRecipePost(
      filter: { tags: { elemMatch: { title: { eq: "Dessert" } } } }
      limit: 6
    ) {
      edges {
        node {
          ...Content
        }
      }
    }
  }
`

export default Posts
